/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React, { useContext, useMemo, useState } from 'react';
import { Timestamp, doc } from 'firebase/firestore';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useFirestoreDocumentMutation } from '@react-query-firebase/firestore';
import { getDownloadURL, uploadBytesResumable, ref as storageRef } from 'firebase/storage';

import toast from 'react-hot-toast';
import { Info, X } from 'react-feather';
import imageCompression from 'browser-image-compression';
import { postFormData, postCategories, features } from '../../static/post';
import {
  generateYupSchema,
  generateFormDefaultValues,
  getBlobFroUri,
  classNames,
} from '../../util/functions';
import {
  storage,
  postCollectionRef,
  userCollectionRef,
} from '../../config/firebase';
import {
  Button,
  Header,
  Input,
  Spinner,
  TextArea,
  Container,
  FormDropDown,
  LocationButton,
  ImageMuiltipleUpload,
  Modal,
} from '../../common';
import { client } from '../../config/react-query';
import { UserContext } from '../../Provider/UserProvider';
import SelectButton from '../../common/SelectButton';
import LocationSelector from '../../common/LocationSelector';
import FormSelect from '../../common/FormSelect';
import { tanstackClient } from '../../App';
/* =============================================================================
<CreatePostScreen />
============================================================================= */
const CreatePostScreen = () => {
  const navigation = useHistory();
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const category = postCategories.find((c) => c.id === categoryId);

  const _toggleSuccessModal = () => setSuccessModal(prev => !prev);

  // POST ID
  const newPostId = useMemo(
    () => Math.random().toString(16).slice(2),
    [],
  );
  // POST CREATE CALL
  const ref = doc(postCollectionRef, newPostId);
  const { mutateAsync: createPost } = useFirestoreDocumentMutation(ref);

  // POST USER GET
  const { user, userId } = useContext(UserContext);

  // POST USER UPADTE CALl
  const userRef = doc(userCollectionRef, userId);
  const { mutateAsync: updateUser } = useFirestoreDocumentMutation(userRef, {
    merge: true,
  });

  // POST FORM
  const fields = postFormData[category.id];
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: generateFormDefaultValues(fields),
    resolver: yupResolver(generateYupSchema(fields)),
  });

  // POST SUBMIT
  const onSubmit = async values => {
    try {
      setLoading(true);
      if (user?.postPackage?.numberOfPosts > 0) {
        const { photos, titleVehicle } = values;

        const newPost = {
          ...values,
          id: newPostId,
          status: 'active',
          isFeatured: false,
          category: category.id,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          author: userId,
          sellerType: user?.type,
          year: values?.year.toString(),
          slug: [
            ...category.slugs,
            ...titleVehicle?.toLowerCase()?.split(' '),
            `${values?.year || ''}`,
          ],
        };

        const photoURLs = await Promise.all(
          photos.map(async photo => {
            const id = `id${Math.random().toString(16).slice(2)}`;
            const _storageRef = storageRef(storage, `post_images/${id}`);
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const imageBlob = await getBlobFroUri(photo);
            const compressedFile = await imageCompression(imageBlob, options);
            const snapshot = await uploadBytesResumable(
              _storageRef,
              compressedFile,
            );
            const url = await getDownloadURL(snapshot.ref);

            return url;
          }),
        );

        newPost.photos = photoURLs;

        if (values?.make) {
          newPost.slug = [...newPost.slug, values?.make.toLowerCase()];
        }
        if (values?.model) {
          newPost.slug = [...newPost.slug, values?.model.toLowerCase()];
        }
        if (!titleVehicle) {
          newPost.titleVehicle = `${values?.year} ${values?.make} ${values?.model}`;
        }

        await createPost(newPost);

        if (user?.postPackage?.numberOfPosts !== 0) {
          await updateUser({
            postPackage: {
              ...user?.postPackage?.numberOfPosts,
              numberOfPosts: user?.postPackage?.numberOfPosts - 1,
            },
          });
        }

        client.invalidateQueries(['posts']);
        tanstackClient.invalidateQueries(['posts']);
        client.invalidateQueries(['myPosts']);

        _toggleSuccessModal();
      } else {
        toast.error('Please buy a plan to post more cars', [
          {
            text: 'Ok',
            onPress: () => navigation.navigate('PostPackages'),
          },
        ]);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  if (!category) {
    return (
      <div>
        <Spinner color="#999" size={45} />
      </div>
    );
  }

  return (
    <Container>
      <Header containerClass="h-16" title={`Post a ${category?.title}`} stack backBtnStyles="-bottom-16" />
      <form onSubmit={handleSubmit(onSubmit)} className="relative max-w-screen-sm space-y-5 mb-5  md:mx-auto mx-5">
        <ImageMuiltipleUpload
          name="photos"
          control={control}
          showAdditionalInfo
          errorText={errors?.photos?.message}
        />
        {fields.map(field => {
          if (field.type === 'text' || field.type === 'number') {
            return (
              <Input
                key={field.name}
                label={field.label}
                value={watch(field.name)} // Ensure this correctly watches the field value
                onChange={e => setValue(field.name, e.target.value)}
                placeholder={field?.placeholder}
                errorText={errors[field.name]?.message}
                formProps={register(field.name)}
              />
            );
          }
          if (field.type === 'textArea') {
            return (
              <TextArea
                key={field.name}
                value={watch(field.name)} // Ensure this correctly watches the field value
                onChange={e => setValue(field.name, e.target.value)}
                label={field.label}
                errorText={errors[field.name]?.message}
                formProps={register(field.name)}
              />
            );
          }
          if (field.type === 'modal') {
            return (
              <FormSelect
                key={field.name}
                data={field.data}
                label={field.label}
                isMultiSelect={field.isMultiSelect}
                control={control}
                name={field.name}
                errorText={errors[field.name]?.message}
              />
            );
          }
          if (field.type === 'select') {
            return (
              <Controller
                name={field.name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectButton
                    data={field.data}
                    value={value}
                    label={field.label}
                    multiSelect={field.isMultiSelect}
                    onChange={onChange}
                  />
                )}
              />

            );
          }
          return null;
        })}
        <div>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationSelector value={value} onChange={onChange} />
            )}
            name="location"
          />
          {!!errors?.location?.message && (
          <div className="flex items-center space-x-1 mt-2">
            <Info color="#dc2626" size={20} />
            <p className="text-md font-medium  py-white text-red-500">
              {errors?.location?.message}
            </p>
          </div>
          )}
        </div>

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="flex items-center">
              <input
                id="default-checkbox"
                type="checkbox"
                value={value}
                className="w-6 h-6 text-red-600 focus:outline-none  border-none  checked:bg-primary  focus:ring-offset-0 focus-visible:outline-none focus:border-0   rounded focus:ring-0"
                onChange={(e) => onChange(e.target.checked)}
              />
              <p className="ml-2 text-md font-medium text-gray-300">
                Do you want to show your phone number in this post?
              </p>
            </div>
          )}
          name="includePhone"
        />
        <Button
          title="POST"
          type="submit"
          size="block"
          disabled={loading}
          loading={loading}
        />
      </form>
      <Modal
        heading="Alert"
        visible={successModal}
        onClose={() => navigation.goBack()}
      >
        <h1 className="text-white text-center text-xl">
          Post Loaded Successfully ✅
        </h1>
        <div className="flex space-x-2 mt-10">
          {/* <Button title="Close" size="block"
          variant="outline" onClick={() => navigation.goBack()} /> */}
          <Button title="OK" size="block" loading={loading} onClick={() => navigation.goBack()} />
        </div>
      </Modal>
    </Container>
  );
};

/* Export
============================================================================= */
export default CreatePostScreen;
