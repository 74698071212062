import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Blogs } from './components/landing/Blogs';
import { Categories } from './components/landing/Categories';
import Hero from './components/landing/Hero';

const LandingPage = () => {
  const { push } = useHistory();
  return (
    <>
      <Helmet>
        <title>Carsnus - Home</title>
      </Helmet>
      <Hero />
      <Categories />
      <div className="mx-auto container px-4 border-b pb-8 border-gray-200  xl:px-0">
        <h2 className="text-2xl md:text-3xl text-gray-800  font-semibold ">
          Cars by Price
        </h2>

        <div className="flex overflow-x-auto gap-4 mt-4">
          <button onClick={() => push('/cars?price=2000')} type="button" className="whitespace-nowrap rounded-lg border border-zinc-200 bg-primary text-white hover:bg-primary hover:text-white px-2 md:px-4 py-2">
            Cars Under $2,000
          </button>
          <button onClick={() => push('/cars?price=4000')} type="button" className="whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary hover:text-white px-2 md:px-4 py-2">
            Cars Under $4,000
          </button>

          <button onClick={() => push('/cars?price=6000')} type="button" className="whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary hover:text-white px-2 md:px-4 py-2">
            Cars Under $6,000
          </button>
          <button onClick={() => push('/cars?price=8000')} type="button" className="whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary hover:text-white px-2 md:px-4 py-2">
            Cars Under $8,000
          </button>
          <button onClick={() => push('/cars?price=10000')} type="button" className="whitespace-nowrap rounded-lg border border-zinc-200 hover:bg-primary hover:text-white px-2 md:px-4 py-2">
            Cars Under $10,000
          </button>
        </div>
      </div>
      <div className="mx-auto container px-4 py-8  xl:px-0">
        <h2 className="text-2xl md:text-3xl text-gray-800  font-semibold ">
          Popular Used Cars for Sale
        </h2>

        <div className="flex justify-between flex-wrap gap-4 mt-4">
          <div className="flex flex-col gap-3">
            <p>Used Toyota Corolla</p>
            <p>Used Ford Mustang</p>
            <p>Used Honda Accord</p>
            <p>Used Honda Civic</p>
            <p>Used Dodge Charger</p>
          </div>
          <div className="flex flex-col gap-3">
            <p>Used Nissan Sentra</p>
            <p>Used Chevrolet Camaro</p>
            <p>Used Ford F150</p>
            <p>Used Chevrolet Silverado</p>
            <p>Used Toyota Tacoma</p>
          </div>
          <div className="flex flex-col gap-3">
            <p>Used Hyundai Elantra</p>
            <p>Used Nissan Murano</p>
            <p>Used Dodge Ram</p>
            <p>Used Nissan Altima</p>
            <p>Used Chevrolet Corvette</p>
          </div>

          <div className="flex flex-col gap-3">
            <p>Used Kia Optima</p>
            <p>Used Kia K5</p>
            <p>Used Chevrolet Volt</p>
            <p>Used Nissan Rogue</p>
            <p>Used Honda Pilot</p>
          </div>
        </div>
      </div>
      <div id="blogs">
        <Blogs />
      </div>
    </>
  );
};

export default LandingPage;
