/* eslint-disable import/no-cycle */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import PostScreen from './PostScreen';
import MyPostsScreen from './MyPostsScreen';
import EditPostScreen from './EditPostScreen';
import PostDataScreen from './PostDataScreen';
import PostQueryScreen from './PostQueryScreen';
import CreatePostScreen from './CreatePostScreen';
import PostFeaturesScreen from './PostFeaturesScreen';
import PostCategorySelectScreen from './PostCategorySelectScreen';

/* =============================================================================
<PostScreens />
============================================================================= */
const PostScreens = () => (
  <>
    <Helmet>
      <title>Posts | Carsnus</title>
    </Helmet>
    <Switch>
      <Route path="/post" exact>
        <PostQueryScreen />
      </Route>
      <Route path="/post/data" exact>
        <PostDataScreen />
      </Route>
      <Route path="/post/my-posts" exact>
        <MyPostsScreen />
      </Route>
      <Route path="/post/category-select" exact>
        <PostCategorySelectScreen />
      </Route>
      <Route path="/post/create/:categoryId" exact>
        <CreatePostScreen />
      </Route>
      <Route path="/post/edit/:id" exact>
        <EditPostScreen />
      </Route>
      <Route path="/post/:id" exact>
        <PostScreen />
      </Route>
      <Route path="/post/:id/features" exact>
        <PostFeaturesScreen />
      </Route>
      {/* <Route path="*">
        <Notfound />
      </Route> */}
    </Switch>
  </>
);

/* Export
============================================================================= */
export default PostScreens;
