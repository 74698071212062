/* eslint-disable import/no-cycle */
import React from 'react';
import { Provider } from 'react-redux';
import { setKey } from 'react-geocode';
import { Toaster } from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClientProvider } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider as TanstackClientProvider, QueryClient as TanstackQueryClient } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import UserProvider from './Provider/UserProvider';
import configureStore from './redux/configureStore';
import AppNavigation from './navigation/AppNavigation';
import { client as queryClient } from './config/react-query';
import {
  GoogleWebClintId,
  GoogleGeoCodingKey,
  StripePublishableKey,
} from './config/secrets';
import ScrollToTop from './components/ScrolltoTop';
import { LocationProvider } from './Provider/LocationProvider';

setKey(GoogleGeoCodingKey);

const stripePromise = loadStripe(StripePublishableKey);

const { persistor, store } = configureStore();

/* =============================================================================
<App />
============================================================================= */
export const tanstackClient = new TanstackQueryClient();

function App() {
  return (
    <TanstackClientProvider client={tanstackClient}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={GoogleWebClintId}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <UserProvider>
                <LocationProvider>
                  <Elements stripe={stripePromise}>
                    <Router>
                      <ScrollToTop />
                      <AppNavigation />
                    </Router>
                  </Elements>
                  <Toaster
                    position="top-right"
                    toastOptions={{
                      duration: 3000,
                    }}
                  />
                </LocationProvider>

              </UserProvider>
            </PersistGate>
          </Provider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </TanstackClientProvider>

  );
}

/* Export
============================================================================= */
export default App;
