/* eslint-disable jsx-quotes */
import React from 'react';
import { RWebShare } from 'react-web-share';
import { FiShare2 } from 'react-icons/fi';
/* =============================================================================
<StoryShareButton />
============================================================================= */
const StoryShareButton = ({ storyId, storyText }) => (
  <div>
    <RWebShare
      data={{
        text: storyText,
        url: `https://carsnus.com/stories/${storyId}`,
        title: 'Carsnus',
      }}
    >
      <button type='button' className="flex items-center pr-2 py-2 space-x-2">
        <FiShare2 color='white' size={22} />
      </button>
    </RWebShare>
  </div>
);

const propsAreEqual = (prevProps, nextProps) => prevProps?.storyId === nextProps?.storyId;

/* Export
============================================================================= */
export default React.memo(StoryShareButton, propsAreEqual);
